<script setup>
import { reactive, onMounted, watch } from "vue";
import { useUserStore } from "../../stores/user";
import { useRoute } from "vue-router/auto";
import pageTitle, { setPageTitle } from "../../utils/pageTitle";
import { shortDateWithTimeTz } from "../../utils/dateUtils";
import useTenants from "../../hooks/useTenants";

const { updatePage, tenantsLoading, tenants, fetchTenants } = useTenants();
const userStore = useUserStore();
const route = useRoute();
const localState = reactive({
  page: parseInt(route.query.page) || 1,
  searchText: "",
  filters: [],
  sort: "id",
});

onMounted(() => {
  setPageTitle("Manage Tenants");
  fetchTenants(localState);
});

watch(localState, () => {
  fetchTenants(localState);
});

watch(
  () => localState.searchText,
  (newVal, _) => {
    if (newVal.length > 0) {
      localState.page = 1;
      localState.filters = [
        {
          column: "name",
          condition: "like",
          value: newVal,
        },
      ];
    } else {
      localState.filters = null;
    }
  }
);
</script>

<template>
  <div v-if="userStore.user && tenants">
    <v-card-title>{{ pageTitle }}</v-card-title>
    <v-card-subtitle
      >Use this section to manage the appplications tenants.
    </v-card-subtitle>
    <v-card class="mx-4 px-4 mt-4">
      <div class="mt-4">
        <v-text-field
          v-model="localState.searchText"
          variant="outlined"
          prepend-inner-icon="mdi-text-box-search-outline"
          label="Search by name"
          color="indigo-lighten-2"
          flat
        ></v-text-field>
        <v-table>
          <thead class="">
            <tr>
              <th class="text-left">Tenant</th>
              <th class="text-left">Created At</th>
              <th class="text-left">Last Updated</th>
              <th class="text-left">Actions</th>
            </tr>
          </thead>
          <tbody v-if="tenantsLoading">
            <tr>
              <td colspan="4" class="text-center">
                <v-progress-circular
                  size="30"
                  indeterminate
                  color="blue-darken-2"
                ></v-progress-circular>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!tenantsLoading && tenants.data.length == 0">
            <tr>
              <td colspan="4" class="text-center">No tenants found.</td>
            </tr>
          </tbody>
          <tbody v-if="!tenantsLoading && tenants && tenants.data.length > 0" class="">
            <tr v-for="user in tenants.data" :key="user.id" style="cursor: pointer">
              <td>
                <v-row class="py-2">
                  <v-col cols="auto" class="d-flex align-center"> {{ user.id }}</v-col>
                  <v-col>
                    <div class="d-flex align-center w-100">
                      <v-avatar size="24" color="surface-variant" class="mr-2"></v-avatar
                      >{{ user.name }}
                    </div>
                    <span class="text-indigo-lighten-2 text-caption">{{
                      user.email ? user.email : "No email"
                    }}</span>
                  </v-col>
                </v-row>
              </td>
              <td>{{ shortDateWithTimeTz(user.created_at) }}</td>
              <td>{{ shortDateWithTimeTz(user.updated_at) }}</td>
              <td>
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <v-btn
                      density="comfortable"
                      icon="mdi-dots-horizontal"
                      elevation="0"
                      v-bind="props"
                    ></v-btn>
                  </template>

                  <v-list>
                    <!-- <v-list-item :to="`/tenants/${user.id}`">
                      <v-list-item-title>View</v-list-item-title>
                    </v-list-item> -->
                    <!-- <v-list-item :to="`/tenants/${user.id}/edit`">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item> -->
                    <!-- <v-list-item @click="deleteSupportTicket(user.id)">
                      <v-list-item-title class="text-error">Delete</v-list-item-title>
                    </v-list-item> -->
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>
    </v-card>
    <div class="text-center mt-4" v-if="tenants && tenants.total_pages > 1">
      <v-pagination
        v-model="localState.page"
        :length="tenants.total_pages"
        @update:modelValue="updatePage"
        rounded="circle"
      ></v-pagination>
    </div>
  </div>
</template>
